import * as React from "react";
import { useEffect, useState } from "react";
import "./styles.scss";
import Logo from "../images/logo.png";
import BG from "../images/bg.png";
import { SocialIcon } from "react-social-icons";

// markup
const IndexPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [bgUrl, setBgUrl] = useState('none');
  useEffect(() => {
    const image = document.createElement("img");
    image.addEventListener("load", () => {
      console.log('loaded!');
      setIsLoaded(true);
      console.log(image.src);
      setBgUrl(image.src);
    });
    image.src = BG;
  }, []);
  const bgStyles = { backgroundImage: `url(${bgUrl})`}
  return (
    <>
      <main>
        <title>De 2 Polarna och Anton</title>
        <h1>
          <img src={Logo} alt="De 2 Polarna och Anton" />
        </h1>
        <section>
          <p>
            Norrländskt vemod i musikform. Sprunget ur skogen, Fjällen och
            vintermörkret. Världen är liten, Lappland är stort - det är{" "}
            <strong>De 2 Polarna och Anton</strong>...
          </p>
          <iframe
            src="https://open.spotify.com/embed/album/0y5KW0BvFh3OBI7yQjY3ap?theme=0"
            width="100%"
            height="300"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
          <p>
            Dikanäs och dess mörka vintrar med vidsträckta vidder och djupa
            skogar formade Antons musikskapande redan under unga år. Efter många
            långa år av musikskapande i ensamhet stötte han på två kompisar som
            hörde något de tyckte om och på den vägen bildades bandet De 2
            Polarna och Anton.
          </p>
          <p>
            Ena polaren, Erik boende i Umeå med rötterna i Vännäs, delar ett
            stort gemensamt intresse i snowboardåkningen med Anton och är en
            extraordinär basist. En resa i musikens värld blev väldigt naturlig
            och självklar.
          </p>
          <p>
            Den andra polaren, Stefan boende Umeå härstammande från Slussfors,
            är en mästerlig trumbatterist men även grym musikproducent. Han är
            den som tagit fram soundet i De 2 Polarna och Anton.
          </p>

          <h2>Än så länge</h2>
          <p>
            Det norrländska vemodet kulminerade i sex låtar som samlades i en EP
            som I januari 2021 släpptes under namnet - Att få bära hundhuvudet.{" "}
            <br />I maj 2021 släpptes singeln - Oblivion
          </p>
          <q>
            (Citat: Veckans band med Zed) “...Marilyn Manson stuk...”
          </q>
          <h2>I nådens år 2022</h2>
          <p>
            Just nu arbetar vi på en ny platta som beräknas släppas under 2022.
            Pandemins skräckgrepp börjar lätta och vi ser fram emot att göra
            liveuppträdanden inför levande och/eller död publik. Väl mött De 2
            Polarna och Anton
          </p>
          <ul>
            <li>
              <strong>Anton Bergsten</strong> - Sång och gitarr
            </li>
            <li>
              <strong>Erik Nåteryd</strong> - Bas
            </li>
            <li>
              <strong>Stefan Johansson</strong> - Trummor och producering
            </li>
          </ul>

          <div className="social">
            <SocialIcon
              bgColor="#000"
              fgColor="#fff"
              url="https://open.spotify.com/artist/57iQ4Tq7rinA2a5c7hs3xU?si=R6HiBW7tSWy-nwm3FSRYxA"
            />
            <SocialIcon
              bgColor="#000"
              fgColor="#fff"
              url="https://www.instagram.com/de2polarnaochanton/"
            />
            <SocialIcon
              bgColor="#000"
              fgColor="#fff"
              url="https://www.facebook.com/de2polarnaochanton/"
            />
          </div>
        </section>
      </main>
      <div 
        className={isLoaded ? "bg-loaded" : "bg"}
        style={bgStyles}
      />
    </>
  );
};

export default IndexPage;
